<template>
  <div class="activation-box">
    <div v-for="item in list" :key="item.codeNum" class="box">
      <div class="left">
        <div>兑换码：{{ item.codeNum }}</div>
        <div class="time">兑换时间：{{ item.changeTime }}</div>
      </div>
      <div class="right">
        {{ item.useState }}
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { PullRefresh, Icon, Toast } from 'vant'
import { getChangeRecord } from '../../services/couponPage'

Vue.use(PullRefresh).use(Icon)
export default {
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    nextTo() {

    },
    getList: async function() {
      const res = await getChangeRecord({
        mid: this.$route.query.mid
      })
      console.log(res)
      if (res.code === 200) {
        this.list = res.data
        if (this.list.length === 0) {
          Toast({
            message: '暂无数据'
          })
        }
      }
    }
  }
}
</script>
<style scoped>
/deep/ #app {
  background: #fff !important;
}

.activation-box {
  padding: 17px 11px;
}
.left div {
  text-align: left;
}
.box {
  width: 100%;
  height: 75px;
  background: #eff1fe;
  border-radius: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 15px;
  color: #333;
  margin-bottom: 15px;
}

.box .left .time {
  padding-top: 10px;
}

.box .right {
  color: #333;
  font-size: 14px;
  width: 86px;
  line-height: 26px;
  text-align: center;
  background: #CFCFCF;
  border-radius: 13px;
}
</style>
